import { useEffect, useState } from "react"
import { useParams, useNavigate, Link } from "react-router-dom"
import { useSelector } from "react-redux"

import "../App.css"

import { selectCategories, selectMenu } from "../redux/menuSlice"
import MenuItemSmall from "./MenuItemSmall"

const BarMenuContent = () => {
  const ALL_ITEMS = "__ALL__"
  const cats = useSelector(selectCategories)
  const fullMenu = useSelector(selectMenu)

  const [categories, setCategories] = useState(cats)
  const [selectedCat, setSelectedCat] = useState(ALL_ITEMS)

  const filterMenu = () => {
    let filtered = [...fullMenu]
    if (selectedCat === ALL_ITEMS) {
      return filtered
    }

    filtered = fullMenu.filter((item) => item.category === selectedCat)
    return filtered
  }

  const [filteredMenu, setfilteredMenu] = useState(fullMenu)

  useEffect(() => {
    setfilteredMenu(filterMenu())
  }, [selectedCat])

  const onSelectCatClick = (catId) => setSelectedCat(catId)

  return (
    <div>
      <div className="menuCategories">
        <button
          key={ALL_ITEMS}
          className={selectedCat === ALL_ITEMS ? "active" : ""}
          id={ALL_ITEMS}
          onClick={() => onSelectCatClick(ALL_ITEMS)}
        >
          All
        </button>
        {categories.map((category) => (
          <button
            key={category.id}
            className={selectedCat === category.id ? "active" : ""}
            id={category.id}
            onClick={() => onSelectCatClick(category.id)}
          >
            {category.name}
          </button>
        ))}
      </div>
      <div className="smallMenuItems">
        {filteredMenu.map((item) => (
          <MenuItemSmall item={item} />
        ))}
      </div>
    </div>
  )
}

export default BarMenuContent
