import { useParams, useNavigate, Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { FaChevronLeft, FaRegHeart, FaHeart } from "react-icons/fa"
import "../App.css"

import { selectSelected } from "../redux/restaurantsSlice"
import BarMenuContent from "./BarMenuContent"
import FavoriteButton from "./FavoriteButton"
import RestaurantHeader from "./RestaurantHeader"

const BarMenu = () => {
  const navigate = useNavigate()

  const bar = useSelector(selectSelected)

  return (
    <div className="menuFlexContainer">
      <div className="restContainer">
        {/* TODO: add a link for "back" button */}
        <RestaurantHeader />
        <BarMenuContent />
      </div>
    </div>

    /* 
      <h3>BarMenu</h3>
      <Link to="1111" className="menuItemLink">
        Guinness beer
      </Link>
      <Link to="2222" className="menuItemLink">
        Carlsberg beer
      </Link>
      <Link to="3333" className="menuItemLink">
        Kilkenny
      </Link> */
  )
}

export default BarMenu
