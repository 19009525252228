import "./App.css"

import { BrowserRouter, Routes, Route } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectSelected } from "./redux/restaurantsSlice"
import Main from "./components/Main"
import BarMenu from "./components/BarMenu"
import BarMenuItem from "./components/BarMenuItem"
import AddedToCart from "./components/AddedToCart"
import Login from "./components/Login"
import Cart from "./components/Cart"
import Payment from "./components/Payment"
import Done from "./components/Done"
import Language from "./components/Language"
import Favorites from "./components/Favorites"
import Share from "./components/Share"
import MainLayout from "./Layouts/MainLayout"

function App() {
  const bar = useSelector(selectSelected)
  console.log(bar)

  return (
    <BrowserRouter>
      <div className="app">
        {/* <main className="app-main"> */}
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Main />} />
            <Route path="barmenu" element={<BarMenu />} />
            <Route path="barmenu/:itemId" element={<BarMenuItem />} />
            <Route path="addtocart/:itemId" element={<AddedToCart />} />
            <Route path="login" element={<Login />} />
            <Route path="cart" element={<Cart />} />
            <Route path="payment" element={<Payment />} />
            <Route path="done" element={<Done />} />
            <Route path="language" element={<Language />} />
            <Route path="favorites" element={<Favorites />} />
            <Route path="share" element={<Share />} />
          </Route>
        </Routes>
        {/* </main> */}
      </div>
    </BrowserRouter>
  )
}

export default App
