import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  selected: {
    id: "1",
    name: "Augustiner-Keller",
    address: "Arnulfstraße 52, 80335 München",
    workingHours: "10:00–00:00",
  },
  restaurants: [
    {
      id: "1",
      name: "Augustiner-Keller",
      address: "Arnulfstraße 52, 80335 München",
      workingHours: "10:00–00:00",
      img: "/Augustiner-Keller.jpg",
      coords: {
        lat: 48.14334475544599,
        lng: 11.551477114840438,
      },
    },
    {
      id: "2",
      name: "Kilians Irish Pub",
      address: "Frauenplatz 11, 80333 München",
      workingHours: "11:00-01:00",
      img: "/Kilians.jpg",
      coords: {
        lat: 48.13875985851137,
        lng: 11.574473510213588,
      },
    },
    {
      id: "3",
      name: "Holy Spirit 1 Bar",
      address: "Heiliggeiststraße 1, 80331 München",
      workingHours: "11:00–01:00",
      img: "/Heiliggeist.jpg",
      coords: {
        lat: 48.135880074514816,
        lng: 11.57771539285738,
      },
    },
    {
      id: "4",
      name: "Haidhauser Brotzeitstand'l",
      address: "Sckellstraße 1, 81667 München",
      workingHours: "11:30–22:00",
      img: "/Haidhauser.jpg",
      coords: {
        lat: 48.134869046267134,
        lng: 11.59583912199687,
      },
    },
  ],
}

const restaurantsSlice = createSlice({
  name: "restaurants",
  initialState: initialState,
  reducers: {
    setSelection: (state, action) => {
      // return { ...state, title: action.payload }
      state.selected = { ...action.payload, selected: true }
    },
    clearSelection: (state) => {
      state.selected = { selected: false }
    },
  },
})

export const { setSelection, clearSelection } = restaurantsSlice.actions

export const selectSelected = (state) => state.restaurants.selected
export const selectRestaurants = (state) => state.restaurants.restaurants

export default restaurantsSlice.reducer
