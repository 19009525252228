import { useState } from "react"
import { Link } from "react-router-dom"
import QRCode from "react-qr-code"
import { useDispatch, useSelector } from "react-redux"

import {
  FaLink,
  FaWhatsapp,
  FaRegEnvelope,
  FaRegFile,
  FaTelegramPlane,
} from "react-icons/fa"

import { selectGift, emptyGift } from "../redux/giftSlice"

const GiftItem = (item) => {
  const { menuId, img, name, restaurantName } = item.item
  return (
    <div className="giftItem">
      <div key={menuId} className="giftItemImage">
        <img src={img} />
      </div>
      <div className="giftItemText">
        {name}
        <br />
        {restaurantName}
      </div>
    </div>
  )
}

const Done = () => {
  const dispatch = useDispatch()

  const [gift, setGift] = useState(useSelector(selectGift))

  const handleDone = () => {
    dispatch(emptyGift())
  }

  return (
    <div className="modalContainer">
      <div className="cheerUpText">Cheer up!</div>
      <div className="barcode-row">
        <div>
          <QRCode
            size={256}
            value={gift.id}
            viewBox={`0 0 256 256`}
            bgColor="white"
            fgColor="#2945a8"
          />
        </div>
      </div>
      {gift.items.map((item) => (
        <GiftItem item={item} />
      ))}
      <div className="giftItem">
        <div className="sendGiftButton">
          <FaWhatsapp />
        </div>
        <div className="sendGiftButton">
          <FaTelegramPlane />
        </div>
        <div className="sendGiftButton">
          <FaRegEnvelope />
        </div>
        <div className="sendGiftButton">
          <FaLink />
        </div>
        <div className="sendGiftButton">
          <FaRegFile />
        </div>
      </div>
      <div>
        <Link to="/">
          <button onClick={handleDone}>Done!</button>
        </Link>
      </div>
    </div>
  )
}

export default Done
