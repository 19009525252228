import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  cart: [],
  quickGift: {},
}

const cartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    addToCart: (state, action) => {
      let changed = false
      state.cart.forEach((item) => {
        if (item.menuId === action.payload.menuId) {
          item.amount = Number(item.amount) + Number(action.payload.amount)
          changed = true
        }
        return item
      })
      if (!changed) {
        state.cart.push(action.payload)
      }
    },
    addQuickGift: (state, action) => {
      state.quickGift = action.payload
    },
    changeQuantity: (state, action) => {
      if (action.payload.amount === 0) {
        state.cart.filter((item) => item.menuId !== action.payload.menuId)
      } else {
        state.cart.forEach((item) => {
          if (item.menuId === action.payload.menuId) {
            item.amount = action.payload.amount
          }
        })
      }
    },
    removeFromCart: (state, action) => {
      state.cart = state.cart.filter((item) => {
        return item.menuId !== action.payload
      })
    },
    emptyCart: (state) => {
      state.cart = initialState.cart
    },
    emptyQuickGift: (state) => {
      state.quickGift = initialState.quickGift
    },
  },
})

export const {
  addToCart,
  addQuickGift,
  changeQuantity,
  removeFromCart,
  emptyCart,
  emptyQuickGift,
} = cartSlice.actions

export const selectCart = (state) => state.cart.cart
export const selectQuickGift = (state) => state.cart.quickGift

export default cartSlice.reducer
