import { useState } from "react"
import { Link } from "react-router-dom"
import { FaArrowRight } from "react-icons/fa"

import "../App.css"

const Restaurant = ({ id, name, address, workingHours, img }) => {
  return (
    <div className="flex-container">
      <div className="restContainer">
        <div>
          <img className="restPhoto" src={img} />
        </div>
        <div className="restCard">
          <h3>{name}</h3>
          <text>
            {address}
            <br />
            {workingHours}
          </text>
        </div>
        <Link to="barmenu">
          <button>
            Menu <FaArrowRight />
          </button>
        </Link>
      </div>
    </div>
  )
}
export default Restaurant
