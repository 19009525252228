import { useParams, useNavigate, Link } from "react-router-dom"
import { FaChevronLeft } from "react-icons/fa"
import { useSelector } from "react-redux"

import { selectSelected } from "../redux/restaurantsSlice"

import FavoriteButton from "./FavoriteButton"

const RestaurantHeader = () => {
  const bar = useSelector(selectSelected)
  const navigate = useNavigate()

  return (
    <>
      <div>
        <img className="restPhoto" src={bar.img} />
      </div>
      <div className="backButton">
        <button onClick={() => navigate(-1)}>
          <FaChevronLeft />
        </button>
      </div>
      <FavoriteButton />
      <div className="restCard">
        <h3>{bar.name}</h3>
        <text>
          {bar.address}
          <br />
          {bar.workingHours}
        </text>
      </div>
    </>
  )
}

export default RestaurantHeader
