import "../App.css"
import Logo from "./assets/Logo.png"
import Globus from "./assets/Glodus.png"
import Heart from "./assets/Heart.png"
import Checkbox from "./assets/Checkbox.png"
import Baloon from "./assets/Baloon.png"
import LeftMenuItem from "./LeftMenuItem"
import Language from "./Language"

const LeftMenu = () => {
  return (
    <div className="leftMenu">
      <LeftMenuItem menuImage={Logo} menuText={"Cheer Up!"} linkTo={"/"} />
      <LeftMenuItem
        menuImage={Globus}
        menuText={"Language"}
        linkTo={"/language"}
      />
      <LeftMenuItem
        menuImage={Heart}
        menuText={"Favorites"}
        linkTo={"favorites"}
      />
      <LeftMenuItem menuImage={Checkbox} menuText={"Cart"} linkTo={"/cart"} />
      <LeftMenuItem menuImage={Baloon} menuText={"Share"} linkTo={"/share"} />
      {/* <LeftMenuItem menuImage={Logo} menuText={""} />
      <LeftMenuItem menuImage={Globus} menuText={""} />
      <LeftMenuItem menuImage={Heart} menuText={""} />
      <LeftMenuItem menuImage={Checkbox} menuText={""} />
      <LeftMenuItem menuImage={Baloon} menuText={""} /> */}
    </div>
  )
}

export default LeftMenu
