import { Link } from "react-router-dom"

import "../App.css"

const LeftMenuItem = ({ menuImage, menuText, linkTo }) => {
  return (
    <Link to={linkTo}>
      <div className="leftMenuItemDiv">
        <img className="leftMenuItemImage" src={menuImage} alt={menuText} />
        <text>{menuText}</text>
      </div>
    </Link>
  )
}

export default LeftMenuItem
