import { configureStore } from "@reduxjs/toolkit"
import restaurantsReducer from "./restaurantsSlice"
import loggedUserReducer from "./userSlice"
import menuReducer from "./menuSlice"
import cartReducer from "./cartSlice"
import giftReducer from "./giftSlice"

const store = configureStore({
  reducer: {
    restaurants: restaurantsReducer,
    loggedUser: loggedUserReducer,
    menu: menuReducer,
    cart: cartReducer,
    gift: giftReducer,
  },
})

export default store
