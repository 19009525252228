import { useState } from "react"
import { useParams, useNavigate, Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuidv4 } from "uuid"
import { FaAngleLeft } from "react-icons/fa"
import "../App.css"

import {
  emptyCart,
  emptyQuickGift,
  selectQuickGift,
  selectCart,
} from "../redux/cartSlice"

import { selectSelected } from "../redux/restaurantsSlice"

import { addGift } from "../redux/giftSlice"

const Payment = () => {
  const dispatch = useDispatch()
  const quickGift = useSelector(selectQuickGift)
  const cart = useSelector(selectCart)
  const bar = useSelector(selectSelected)

  const onCancel = () => {
    dispatch(emptyQuickGift())
  }

  const onPay = () => {
    let theGift = {
      id: uuidv4(),
      items: [],
    }

    if (quickGift.price) {
      theGift.items.push(quickGift)
    } else {
      theGift.items = cart
    }

    dispatch(addGift(theGift))

    dispatch(emptyQuickGift())
    dispatch(emptyCart())
  }

  const calcTotal = () => {
    if (quickGift.price) {
      return quickGift.price
    } else {
      let sum = 0
      cart.forEach((item) => (sum += item.price * item.amount))
      return sum
    }
  }

  const [sum, setSum] = useState(calcTotal())

  return (
    <div className="modalContainer">
      <h3>
        Payment
        <br />
        You are about to pay for {sum} Eur.
      </h3>
      <form className="paymentForm">
        <label>
          Card Holder Name
          <input type="text" id="cardhoder" />
        </label>
        <label>
          Card Number
          <input type="text" id="cardnumber" />
        </label>
        <div className="expCvv">
          <label>
            Expires
            <input type="text" id="expires" />
          </label>
          <label>
            CVV
            <input type="text" id="cvv" />
          </label>
        </div>
        <div className="paymentButtons">
          <Link Link to="/done">
            <button onClick={onPay}>Pay</button>
          </Link>
          <Link to="/">
            <button onClick={onCancel}>Cancel</button>
          </Link>
        </div>
      </form>
    </div>
  )
}

export default Payment
