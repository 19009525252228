import { useState } from "react"
import { useDispatch } from "react-redux"
import { FaRegTrashAlt } from "react-icons/fa"

import {
  changeQuantity,
  removeFromCart,
  emptyCart,
  selectCart,
} from "../redux/cartSlice"

import "../App.css"

const CartItem = (item) => {
  const { menuId, img, name, price, amount } = item.item

  const dispatch = useDispatch()

  const [total, setTotal] = useState(price * amount)

  const handleDelete = (itemId) => {
    dispatch(removeFromCart(itemId))
  }

  const [amountValue, setAmountValue] = useState(amount)

  const handleAmountChange = (e) => {
    if (e.target.value > 0) {
      dispatch(
        changeQuantity({ menuId: menuId, amount: Number(e.target.value) })
      )
      setTotal(e.target.value * price)
      // setAmountValue(e.target.value)
    }
  }

  return (
    <div className="cartItem">
      <div key={menuId} className="cartItemImage">
        <img src={img} />
      </div>
      <div className="cartItemText">
        <div className="cartItemTextText">
          {name}
          <br />
          {price} Eur x{" "}
          <input
            type="number"
            value={amount}
            onChange={(e) => handleAmountChange(e)}
          />{" "}
          ={total} Eur
        </div>
        <div className="deleteItemDiv">
          <FaRegTrashAlt
            className="deleteItem"
            onClick={() => handleDelete(menuId)}
          />
        </div>
      </div>
    </div>
  )
}

export default CartItem
