import { useParams, useNavigate, Link } from "react-router-dom"
import { useSelector } from "react-redux"

import { selectSelected } from "../redux/restaurantsSlice"

import "../App.css"
import RestaurantHeader from "./RestaurantHeader"
import MenuItemAddToCart from "./MenuItemAddToCart"

const BarMenuItem = () => {
  const { itemId } = useParams()

  const bar = useSelector(selectSelected)
  const navigate = useNavigate()

  return (
    <div className="flexContainer">
      <div className="restContainer">
        <RestaurantHeader />
        <MenuItemAddToCart itemId={itemId} />
      </div>
    </div>
  )
}

export default BarMenuItem
