import { useState, useEffect } from "react"
import { useParams, useNavigate, Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { FaAngleLeft } from "react-icons/fa"

import {
  changeQuantity,
  removeFromCart,
  emptyCart,
  selectCart,
} from "../redux/cartSlice"

import CartItem from "./CartItem"

import "../App.css"

const Cart = () => {
  const dispatch = useDispatch()

  const cart = useSelector(selectCart)

  const calcTotal = () => {
    let sum = 0
    cart.forEach((item) => (sum += item.price * item.amount))
    return sum
  }

  const [total, setTotal] = useState(calcTotal())

  useEffect(() => {
    setTotal(calcTotal())
  }, [cart])

  return (
    <div className="modalContainer">
      <h3>Cart</h3>
      {cart.length === 0 && "Cart is empty"}
      {cart.map((item) => (
        <CartItem item={item} />
      ))}
      <div>Total : {total} Eur</div>
      {cart.length !== 0 && (
        <Link Link to="/payment">
          <button style={{ width: "75%" }}>Pay and send this gift</button>
        </Link>
      )}
      <Link to="/">
        <button style={{ width: "75%" }}>Continue finding gifts</button>
      </Link>
    </div>
  )
}

export default Cart
