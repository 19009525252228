import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  categories: [
    {
      id: "1",
      name: "Coffee",
    },
    {
      id: "2",
      name: "Beer",
    },
    {
      id: "3",
      name: "Long drinks",
    },
  ],
  menu: [
    {
      id: "10",
      category: "1",
      name: "Espresso",
      img: "/Espresso.jpg",
      price: 1.5,
    },
    {
      id: "20",
      category: "1",
      name: "Capucccino",
      img: "/latte-coffee.jpg",
      price: 1.5,
    },
    {
      id: "30",
      category: "1",
      name: "Latte",
      img: "/latte-coffee.jpg",
      price: 1.5,
    },
    {
      id: "40",
      category: "2",
      name: "Guinness",
      img: "/Guinness.jpg",
      price: 4.5,
    },
    {
      id: "50",
      category: "2",
      name: "Kilkenny",
      img: "/Kilkenny.jpg",
      price: 4.5,
    },
    {
      id: "60",
      category: "2",
      name: "Heineken",
      img: "/Heineken.png",
      price: 5.2,
    },
    {
      id: "70",
      category: "2",
      name: "Pilsner Urquell",
      img: "/Urquell.jpg",
      price: 5.5,
    },
  ],
}

const menuSlice = createSlice({
  name: "menu",
  initialState: initialState,
  reducers: {},
})

export const selectCategories = (state) => state.menu.categories
export const selectMenu = (state) => state.menu.menu

export default menuSlice.reducer
