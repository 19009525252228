import React, { useState, useCallback, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api"

import Restaurant from "./Restaurant"

import {
  setSelection,
  clearSelection,
  selectRestaurants,
} from "../redux/restaurantsSlice"

const API_KEY = process.env.REACT_APP_API_KEY

// import RestPhoto from "./assets/Logo.png"

const containerStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  alignItems: "center",
  justifyContent: "flex-end",
}

const center = {
  lat: 48.1406608755886,
  lng: 11.5799652365683,
}

const initialZoom = 14

// Restaurant props goes here

//

function GoogleMapComponent() {
  const dispatch = useDispatch()
  const bars = useSelector(selectRestaurants)

  //const [zoom, setZoom] = useState(14)
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
  })

  const [map, setMap] = React.useState(null)

  const options = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
  }

  const onLoad = useCallback(function callback(map) {
    //This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center)
    map.fitBounds(bounds)
    setMap(map)
  }, [])

  const onLoadMarker = (marker) => {}

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const onMarkerClick = (bar) => {
    dispatch(setSelection(bar))
  }

  const onClick = () => {
    dispatch(clearSelection())
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      options={options}
      center={center}
      zoom={initialZoom}
      //onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={onClick}
    >
      {/* Child components, such as markers, info windows, etc. */}
      {bars?.map((theBar) => (
        <MarkerF
          key={theBar.id}
          position={theBar.coords}
          onLoad={onLoadMarker}
          title={theBar.name}
          icon={{
            url: require("./assets/favicon-32x32.png"),
            //scale: 0.05,
          }}
          onClick={() => {
            onMarkerClick(theBar)
          }}
        />
      ))}
    </GoogleMap>
  ) : (
    <div>Loading...</div>
  )
}

export default React.memo(GoogleMapComponent)
