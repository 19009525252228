import NotImmplemented from "./NotImplemented"
import "../App.css"

const Favorites = () => {
  return (
    <div className="modalContainer">
      <h3>Favorites</h3>
      <NotImmplemented />
    </div>
  )
}

export default Favorites
