import { useParams, useNavigate, Link } from "react-router-dom"

const MenuItemSmall = (props) => {
  const { id, categoty, name, img, price } = props.item

  return (
    <div className="menuItemSmall">
      <Link to={id}>
        <img src={img} />
        {name}
        <div className="itemPriceContainer">
          <div className="itemPrice">{price} Eur</div>
        </div>
      </Link>
    </div>
  )
}

export default MenuItemSmall
