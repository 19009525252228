import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams, useNavigate, Link } from "react-router-dom"
import { selectSelected } from "../redux/restaurantsSlice"
import { selectMenu } from "../redux/menuSlice"
import { selectCart, addToCart, addQuickGift } from "../redux/cartSlice"

import "../App.css"

const MenuItemAddToCart = () => {
  const { itemId } = useParams()

  const dispatch = useDispatch()
  const bar = useSelector(selectSelected)
  const fullMenu = useSelector(selectMenu)

  const [amount, setAmount] = useState(1)

  const navigate = useNavigate()

  const filteredMenu = fullMenu.filter((item) => item.id === itemId)
  const menuItem = filteredMenu ? filteredMenu[0] : null

  const [totalAmount, setTotalAmount] = useState(menuItem.price)

  const quickMadeAGift = () => {
    dispatch(
      addQuickGift({
        menuId: menuItem.id,
        category: menuItem.category,
        name: menuItem.name,
        img: menuItem.img,
        price: menuItem.price,
        amount: Number(amount),
        restaurantId: bar.id,
        restaurantName: bar.name,
        restaurantImg: bar.img,
      })
    )
    navigate("/payment")
  }

  const addItemToCart = () => {
    dispatch(
      addToCart({
        menuId: menuItem.id,
        category: menuItem.category,
        name: menuItem.name,
        img: menuItem.img,
        price: menuItem.price,
        amount: Number(amount),
        restaurantId: bar.id,
        restaurantName: bar.name,
        restaurantImg: bar.img,
      })
    )
    navigate(`/addtocart/${itemId}`)
  }

  const handleAmountChange = (e) => {
    if (e.target.value >= 0) {
      setAmount(e.target.value)
      setTotalAmount(e.target.value * menuItem.price)
    }
  }

  return menuItem ? (
    <div className="itemSelectedContainer">
      <div className="itemImageSelected">
        <img src={menuItem.img} />
      </div>
      <div className="itemDetailsSelected">
        <div className="nameAndPrice">
          {menuItem.name}
          <br />
          {menuItem.price} Eur.
        </div>
        <form className="itemSelectedForm">
          <label>Amount: </label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => handleAmountChange(e)}
          />
        </form>
        <div className="nameAndPrice">Total: {totalAmount} Eur.</div>
        <button
          style={{
            width: "100%",
          }}
          onClick={addItemToCart}
        >
          Add to cart
        </button>

        <button
          style={{
            width: "100%",
          }}
          onClick={quickMadeAGift}
        >
          Make a quick gift
        </button>
      </div>
    </div>
  ) : (
    <div>{`Menu item with ID=${itemId} not found`}</div>
  )
  /* // <div className="restContainer">
    //   <button onClick={() => navigate(-1)}>
    //     <FaAngleLeft />
    //   </button>
    //   <h3>BarMenu Item</h3>
    //   Item selected: {itemId}
    //   <Link Link to={`/addtocart/${itemId}`}>
    //     <button>Add to cart</button>
    //   </Link>
    //   <button onClick={quickMadeAGift}>Make a gift</button>
    // </div> */
}

export default MenuItemAddToCart
