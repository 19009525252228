import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  id: "",
  items: [],
}

const giftSlice = createSlice({
  name: "gift",
  initialState: initialState,
  reducers: {
    addGift: (state, action) => {
      state.id = action.payload.id
      state.restaurantId = action.payload.restaurantId
      state.restaurantName = action.payload.restaurantName
      state.restaurantImg = action.payload.restaurantImg
      state.items = action.payload.items
    },
    emptyGift: (state) => {
      state.id = initialState.id
      state.items = initialState.items
    },
  },
})

export const { addGift, emptyGift } = giftSlice.actions

export const selectGift = (state) => state.gift

export default giftSlice.reducer
