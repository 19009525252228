import { useParams, useNavigate, Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { FaAngleLeft } from "react-icons/fa"

import { selectMenu } from "../redux/menuSlice"

import "../App.css"

const AddedToCart = () => {
  const navigate = useNavigate()
  const { itemId } = useParams()

  const fullMenu = useSelector(selectMenu)
  const filteredMenu = fullMenu.filter((item) => item.id === itemId)
  const menuItem = filteredMenu ? filteredMenu[0] : null

  return menuItem ? (
    <div
      className="modalContainer"
      style={{ paddingLeft: "10px", paddingRight: "10px" }}
    >
      <h3 style={{ marginLeft: "30px", marginRight: "30px" }}>
        Added to cart:
      </h3>
      <h2 style={{ marginLeft: "30px", marginRight: "30px" }}>
        {menuItem.name}
      </h2>
      <Link to="/barmenu">
        <button style={{ width: "100%" }}>
          <FaAngleLeft /> Return to menu
        </button>
      </Link>

      <Link to="/cart">
        <button style={{ width: "100%" }}>
          Go to cart for checkout and payment
        </button>
      </Link>
    </div>
  ) : (
    <div>{`Menu item with ID=${itemId} not found`}</div>
  )
}

export default AddedToCart
