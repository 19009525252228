import { FaChevronLeft, FaRegHeart, FaHeart } from "react-icons/fa"

const FavoriteButton = () => {
  const addToFavorite = () => {}

  return (
    <div className="favoriteButtonContainer">
      <button onClick={() => addToFavorite()}>
        <FaRegHeart className="favButtonIcon" />
      </button>
    </div>
  )
}

export default FavoriteButton
