import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loggedIn: false,
  userId: "",
}

const loggedUserSlice = createSlice({
  name: "loggedUser",
  initialState: initialState,
  reducers: {
    setLogin: (state, action) => {
      // return { ...state, title: action.payload }
      state = { loggedIn: true, userId: action.payload }
    },
    setLogout: (state) => {
      state = initialState
    },
  },
})

export const { setLogin, setLogout } = loggedUserSlice.actions

export const selectUser = (state) => state.loggedUser

export default loggedUserSlice.reducer
