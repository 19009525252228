import { FaFrown } from "react-icons/fa"

const NotImplemented = () => {
  return (
    <div style={{ margin: "30px" }}>
      Not Implemented yet <FaFrown />
    </div>
  )
}

export default NotImplemented
