import { useSelector } from "react-redux"
import { selectSelected } from "../redux/restaurantsSlice"

import GoogleMapComponent from "./GoogleMapComponent"
import LeftMenu from "./LeftMenu"
import Restaurant from "./Restaurant"

const Main = () => {
  const bar = useSelector(selectSelected)

  return (
    <>
      <GoogleMapComponent />
      {/* <LeftMenu /> */}
      {bar.selected ? (
        <Restaurant
          id={bar.id}
          name={bar.name}
          address={bar.address}
          workingHours={bar.workingHours}
          img={bar.img}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default Main
